import { usePage } from 'modules/page/pageSelector';
import { MODULE_STATUS } from 'constants/modules';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageData } from 'types/Page';
import { Button, SocialMediaIcon, SocialMediaIconProps } from '@kontentino/ui';
import PageTypeUtils from 'app/utils/pageType';
import ArrayUtils from 'app/utils/array';
import Skeleton from 'react-loading-skeleton';
import { addQueryParams } from 'utils/url';
import { modalRoutes } from 'constants/routes';
import { useSocialLoginContext } from 'app/modules/socialLogin/context';

function groupPagesByType(pages: PageData[]) {
  const grouped = pages
    .filter((page) => !page.isConnected)
    .reduce((acc: { [key: number]: PageData[] }, item: PageData) => {
      if (!acc[item.type]) {
        acc[item.type] = [];
      }
      acc[item.type].push(item);
      return acc;
    }, {});

  return Object.entries(grouped).map(([type, data]) => ({
    value: Number(type),
    data,
  }));
}

const DisconnectedProfilesList = () => {
  const { t } = useTranslation();
  const socialLogin = useSocialLoginContext();

  const { pages, status } = usePage();

  if (status === MODULE_STATUS.Loading) {
    return (
      <div className="tw-flex tw-flex-col tw-gap-5 tw-py-6">
        {ArrayUtils.generate(5).map((_, index) => (
          <Skeleton key={index} height={28} />
        ))}
      </div>
    );
  }

  if (groupPagesByType(pages).length === 0) {
    return (
      <div className="tw-flex tw-flex-col tw-gap-5 tw-py-24">
        <span className="tw-text-center tw-text-md tw-font-medium tw-text-grayscale-100">
          {t('disconnectedProfilesModal.emptyDescription')}
        </span>
      </div>
    );
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-5 tw-py-6">
      {groupPagesByType(pages).map((option) => (
        <div
          key={option.value}
          className="tw-flex tw-items-center tw-justify-between"
        >
          <div className="tw-flex tw-items-center tw-gap-2">
            <SocialMediaIcon
              size={24}
              type={
                PageTypeUtils.getNameFromNumber(
                  option.value,
                ) as SocialMediaIconProps['type']
              }
            />
            <span className="tw-line-clamp-1 tw-overflow-hidden tw-text-ellipsis tw-break-all tw-text-md tw-font-medium">
              {t('disconnectedProfilesModal.option', {
                count: option.data.length,
              })}
            </span>
          </div>
          <Button
            variant="danger"
            size="small"
            onClick={() => {
              socialLogin.authorize({
                pageType: option.value,
                redirectUrl: `${addQueryParams(
                  window.location.href.replace(window.location.hash, ''),
                  {
                    type: option.value,
                  },
                )}#${modalRoutes.ASSOCIATE_POSTS_PROFILE}`,
              });
            }}
            data-name="profile-selector_reconnect-profile-button"
          >
            {t('reconnect')}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default DisconnectedProfilesList;
