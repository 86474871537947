import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Field, Select, TextInput } from '@kontentino/ui';
import ValidationScheme from 'app/constants/validationSchemes';
import { UserRole } from 'types/User';
import AssignProfileSelects from './AssignProfileSelects';
import { useUser } from 'modules/user/userSelector';
import { userRoles, ValidUserRole } from './userRoles';

const InviteUserformSchema = z.object({
  name: z
    .string()
    .max(
      50,
      'First name is too long. Please enter a name with maximum 50 characters.',
    )
    .optional(),
  surname: z
    .string()
    .max(
      50,
      'Last name is too long. Please enter a name with maximum 50 characters.',
    )
    .optional(),
  email: ValidationScheme.Email,
  role: ValidationScheme.RequiredString(),
  selectedProfiles: z.array(z.number()).default([]).optional(),
  selectedGroups: z.array(z.number()).default([]).optional(),
});

export type InviteUserFormState = z.infer<typeof InviteUserformSchema>;

export const defaultValues: InviteUserFormState = {
  name: '',
  surname: '',
  email: '',
  role: '',
  selectedProfiles: [],
  selectedGroups: [],
};

type OptionType = { value: UserRole; label: string };

const userRoleOptions: OptionType[] = (
  Object.keys(userRoles) as ValidUserRole[]
).map((role) => ({
  value: role,
  label: userRoles[role],
}));

type InviteUserFormProps = {
  onSubmit: (data: InviteUserFormState) => void;
  isSubmitting: boolean;
  onValidityChange?: (isValid: boolean) => void;
  serverError?: string;
};

const InviteUserForm = ({
  onSubmit,
  isSubmitting,
  onValidityChange,
  serverError,
}: InviteUserFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    setError,
    clearErrors,
  } = useForm<InviteUserFormState>({
    defaultValues,
    resolver: zodResolver(InviteUserformSchema),
    mode: 'onChange',
  });
  const { role } = useUser();

  const filteredRoleOptions =
    role === 'admin'
      ? userRoleOptions
      : userRoleOptions.filter((option) => option.value !== 'admin');

  useEffect(() => {
    onValidityChange?.(isValid);
  }, [isValid, onValidityChange]);

  useEffect(() => {
    if (serverError && isSubmitted) {
      setError('email', { message: serverError });
    } else {
      clearErrors('email');
    }
  }, [serverError, setError, clearErrors, isSubmitted]);

  return (
    <form
      id="invite-user-form"
      onSubmit={handleSubmit(onSubmit)}
      className="tw-flex tw-flex-col tw-gap-6"
    >
      <Field.Group>
        <Field.Label required>Role</Field.Label>
        <Controller
          name="role"
          control={control}
          render={({ field: { onChange, value, ...restField } }) => {
            const selectedOption =
              filteredRoleOptions.find((option) => option.value === value) ||
              null;
            return (
              <Select
                {...restField}
                value={selectedOption}
                onChange={(option) => onChange((option as OptionType).value)}
                options={filteredRoleOptions}
                isDisabled={isSubmitting}
                getOptionValue={(option: OptionType) => option.value}
                getOptionLabel={(option: OptionType) => option.label}
                placeholder="Select role"
                isSearchable={false}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (styles) => ({
                    ...styles,
                    zIndex: 1010,
                  }),
                }}
              />
            );
          }}
        />
        <Field.Error>{errors.role?.message}</Field.Error>
      </Field.Group>

      <Field.Group>
        <Field.Label>First name</Field.Label>
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              {...field}
              error={!!error?.message}
              disabled={isSubmitting}
            />
          )}
        />
        <Field.Error>{errors.name?.message}</Field.Error>
      </Field.Group>

      <Field.Group>
        <Field.Label>Last name</Field.Label>
        <Controller
          name="surname"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              {...field}
              error={!!error?.message}
              disabled={isSubmitting}
            />
          )}
        />
        <Field.Error>{errors.surname?.message}</Field.Error>
      </Field.Group>

      <Field.Group>
        <Field.Label required>Email</Field.Label>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              {...field}
              type="email"
              error={!!error?.message}
              disabled={isSubmitting}
            />
          )}
        />
        <Field.Caption>
          This user will receive their password at this email address
        </Field.Caption>
        <Field.Error>{errors.email?.message}</Field.Error>
      </Field.Group>

      <AssignProfileSelects control={control} isDisabled={isSubmitting} />
    </form>
  );
};

export default InviteUserForm;
